import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { ContainerDesktop } from "components/Container";
import { PageHeaderDesktop } from "components/PageHeader/PageHeaderDesktop";
import { useAuthStore } from "modules/Auth/authStore";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import {
  useAccessCourseTenant,
  useCompetenciesTenant,
} from "modules/Explore/exploreApi";
import { trackEvent } from "lib/amplitude";
import { useStateWithMemo } from "lib/useStateWithMemo";
import debounce from "lodash.debounce";
import { IconFilter } from "components/Icon/IconFilter";
import Modal from "components/ModalPortal/Modal";
import { useIsDesktop } from "lib/device";
import { BottomNav } from "modules/Nav/BottomNav";
import Section from "./Section";
import {
  getSubDomain,
  isBlueBirdURL,
  isMalukuURL,
  useIsSubdomain,
  useIsTenant,
} from "lib/host";
import { FreeTrialBanner } from "modules/Home/FreeTrialBanner";
import { useMyClassApi } from "modules/MyClass/myClassApi";
import { SkeletonRect, SkeletonWrapper } from "components/Skeleton/Skeleton";
import {
  SECTION_BASIC,
  SECTION_PLUS,
  SECTION_PRO,
  mapArrayToSection,
  mapTagsToSection,
  SECTION_GERBANGSARI,
  SECTION_MALUKU,
} from "lib/sections";
import useInfiniteScroll from "lib/useInfiniteScroll";
import { FilterTenant } from "modules/Tenant/FilterTenant";
import {
  useGetCoursesMandatoryByTenantSlug,
  useGetOthersByTenantSlug,
  useGetSectionCustomByTenantSlug,
  useJPTrackers,
} from "modules/Tenant/homeTenantApi";
import HomeCard from "./HomeCard";
import { useCourseStore } from "modules/Course/courseStore";
import IconClose from "components/Icon/IconClose";
import { KYC } from "./KYC";
import { useUserGetProfile, useUserPreference } from "modules/User/userApi";
import SectionMandatory from "./SectionMandatory";
import { DownloadAppBanner } from "components/Banner/DownloadAppBanner";
import gerbangsari from "data/tenants/gerbangsari.json";
import { isBrowser } from "lib/ssr";
import { checkTawkTo } from "lib/tawk-to";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import CardContinue from "./CardContinue";
import HomeCardKeywords from "./HomeCardKeywords";
import { HorizontalScroll } from "components/Pagination/HorizontalScroll";
import Keywords from "./Keywords";
import ModalSectionsDetail from "./ModalSectionsDetail";
import { useSearchStore } from "components/InstantSearchBox/searchStore";
import { PopularCourse } from "components/Card/interfaces";
import { InteractiveAvatar } from "components/InteractiveAvatar";
import { getAllCoursesFromIndexedDB } from "lib/course";
import { ArrayObjectSortBy, parseDataParams, ShuffleArray } from "lib/object";
import { generateIndexName } from "lib/algolia";
import { CONST } from "lib/const";
import { useLearningHourStore } from "./learningHourStore";

export const HomeTenant = ({ textValue = null }) => {
  const [hasMoreMandatory, setHasMoreMandatory] = useState(true);
  const [pageMandatory, setPageMandatory] = useState(1);
  const domain = typeof window !== "undefined" && window.location.hostname;
  // const [page, setPage] = useState({
  //   page: 1,
  // });
  const { currentUser } = useAuthStore((state) => ({
    loginState: state.loginState,
    currentUser: state.currentUser,
  }));
  const [searchText, setSearch] = useState(null);
  const router = useRouter();
  const [currentSort, setCurrentSort] = useState({});
  const [filters, setFilters] = useStateWithMemo({});
  const [maxItem, setMaxItem] = useState(0);
  const [sortDesc, setSortByDesc] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const isSubdomainTenant = useIsSubdomain();
  const [show, setShow] = useState(true);
  const isDesktop = useIsDesktop();
  const isSearchPage = router?.pathname.includes("search");
  const [categories, setCategories] = useState([]);
  const [allIns, setInstitutions] = useState([]);
  const [competencesFilter, setCompetencesFilter] = useState([]);
  const [competencesKYC, setCompetencesKYC] = useState([]);
  const [showKYC, setShowKYC] = useState(false);
  const [screenWidth, setScreenWidth] = useState(1200);
  const [selectedKYCTags, setKYCTags] = useState([]);
  const [onGoingCourses, setOnGoingCourses] = useState([]);
  const [keywordOthers, setKeywordOthers] = useState([]);
  const [coursesAfterKeywordClicked, setCoursesAfterClicked] = useState([]);
  const { t } = useTranslation("common");
  const myClassAPI = useMyClassApi();
  const [isDoneFetching, setFetching] = useState(false);
  const [mandatoryCourses, setMandatoryCourses] = useState([]);
  // const notifications = useGetNotificationApi();
  const { mandatoryCount } = useCourseStore((s) => ({
    mandatoryCount: s.mandatoryCount,
  }));
  const tabsId = router.query.tabsId ?? null;
  const getSectionCustom = useGetSectionCustomByTenantSlug(
    currentUser?.referral
  );
  const getCourseMandatory = useGetCoursesMandatoryByTenantSlug(
    currentUser?.referral
  );
  const getOthers = useGetOthersByTenantSlug();
  const [sectionPosition, setSectionPosition] = useState([]);

  const [sectionB2B, setSectionB2B] = useState([]);
  const [sectionMandatory, setSectionMandatory] = useState([]);
  const [sectionsMandatory, setSectionsMandatory] = useState([]);

  const {
    totalFilter,
    setTotalFilter,
    popularCourse,
    setPopularCourse,
    keywords,
    setKeywords,
    indexName,
  } = useSearchStore((state) => ({
    totalFilter: state.totalFilter,
    setTotalFilter: state.setTotalFilter,
    popularCourse: state.popularCourse,
    setPopularCourse: state.setPopularCourse,
    keywords: state.keywords,
    setKeywords: state.setKeywords,
    indexName: state.indexName,
  }));
  const isMobileView = typeof window !== "undefined" && window.innerWidth < 768;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch popular keywords
        const resKeywords = await fetch(
          `/api/analytics-algolia?indexName=${indexName}`
        );
        const dataKeywords = await resKeywords.json();

        let keywordTemp: string[] = [];
        const searches = dataKeywords?.data?.searches.filter((a) => a.search);
        if (searches) {
          searches.forEach((item) => {
            keywordTemp.push(item.search);
          });
          setKeywords(keywordTemp); // Set keywords once fetched
        }

        // Fetch popular courses only after keywords are available
        if (keywordTemp.length > 0) {
          let dataCourseTemp: PopularCourse[] = [];
          const coursePromises = keywordTemp.map(async (keyword) => {
            const resCourses = await fetch(
              keyword
                ? `/api/search-algolia?indexName=${indexName}&q=${keyword}`
                : `/api/search-algolia?indexName=${indexName}`
            );
            const dataCourses = await resCourses.json();

            if (dataCourses?.data?.hits.length > 0) {
              const filterData = dataCourses?.data?.hits.filter(
                (a) => a?.is_subscribe === true
              );

              dataCourseTemp = dataCourseTemp.concat(filterData);
            }
          });

          // Wait for all course fetches to complete
          await Promise.all(coursePromises);

          // Remove duplicates based on 'slug'
          const finalData = dataCourseTemp.filter(
            (course, index, self) =>
              index === self.findIndex((c) => c.slug === course.slug)
          );

          setPopularCourse(finalData); // Set courses only after all data is fetched
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call the async function
  }, [indexName]); // Depend on indexName to re-fetch if it changes

  const onReset = () => {
    setPage(1);
    setSortByDesc("");
    setFilters({});
    setCurrentSort({});
  };

  //@ts-ignore
  const exploreCompetences = useCompetenciesTenant();

  const modifytags = (arr) => {
    return arr.map((item) => {
      const transformedTags = item.tags
        .filter((a) => a.total_courses > 0)
        .map((tag) => ({
          ...tag,
          selected: false,
        }));

      return {
        name: item.name,
        selected: false,
        slug: item.slug,
        tags: transformedTags,
      };
    });
  };
  const subDomain = isBrowser() ? getSubDomain(window.location.hostname) : null;
  const referral = isSubdomainTenant
    ? currentUser?.referral ?? subDomain
    : currentUser?.referral;

  useEffect(() => {
    if (referral) {
      exploreCompetences.doFetch(
        {
          slug:
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : referral,
        },
        {
          onSuccess(res: any) {
            const filterData = modifytags(res.data);
            const KYC = modifytags(res.data);
            setCompetencesFilter(filterData.filter((a) => a.tags.length > 0));
            setCompetencesKYC(KYC);
          },
        }
      );
      // Show Tawk To
      checkTawkTo(currentUser);
    }
  }, [referral]);

  useEffect(() => {
    if (
      currentUser &&
      !(currentUser as any)?.has_kyc_submitted &&
      competencesKYC.length > 0 &&
      // Hide KYC if Tenant Section exist (4/4/24)
      sectionPosition.length === 0
    ) {
      const plan = (currentUser?.tenant as any)?.plan?.name;
      const screen = typeof window !== "undefined" && window.innerWidth;

      if (["Enterprise Plus", "Enterprise"].includes(plan)) {
        setShowKYC(true);
      }
      setScreenWidth(screen);
    }
  }, [competencesKYC, sectionPosition]);

  useEffect(() => {
    if (showKYC) {
      // @ts-ignore
      trackEvent({
        event: "myinterest_s",
      });
    }
  }, [showKYC]);

  useEffect(() => {
    if (currentUser) {
      myClassAPI.doFetch(
        {
          status: "on_progress",
          // @ts-ignore
          limit: 12,
          // @ts-ignore
          page: 1,
        },
        {
          onSuccess(res) {
            setOnGoingCourses(
              res?.data
                .filter((el) => el.enrollment_status == "on_progress")
                .slice(0, 3)
            );
          },
        }
      );
    }
  }, [currentUser]);

  useEffect(() => {
    // if (referral && sectionB2B.length === 0) {
    //   const plan = currentUser?.tenant?.plan?.name;
    //   let currentTags = [];

    //   if (referral === "arwanacitra") {
    //     currentTags = mapTagsToSection([
    //       "Personal Development",
    //       "Performance & Productivity",
    //       "Entrepreneurship",
    //     ]);
    //   } else if (gerbangsari.includes(referral)) {
    //     currentTags = mapTagsToSection(SECTION_GERBANGSARI);
    //   } else {
    //     if(loginState == 'LOGIN') {
    //       if (currentUser.list_tags && currentUser?.list_tags?.length > 0) {
    //         currentTags = mapArrayToSection((currentUser as any)?.list_tags);
    //       } else {
    //         switch (plan) {
    //           case "Enterprise Plus":
    //             currentTags = SECTION_PLUS;
    //             break;

    //           case "Pro":
    //             currentTags = SECTION_PRO;
    //             break;

    //           default:
    //             currentTags = SECTION_BASIC;
    //             break;
    //         }
    //       }
    //     } else {
    //       currentTags = SECTION_BASIC;
    //     }
    //   }
    //   setSectionB2B(currentTags);
    // }

    let currentTags = [];
    // Gerbangsari default sections
    if (gerbangsari.includes(referral)) {
      currentTags = mapTagsToSection(SECTION_GERBANGSARI);
    } else if (
      typeof window !== "undefined" &&
      isMalukuURL(window.location.hostname)
    ) {
      currentTags = mapTagsToSection(SECTION_MALUKU);
    } else {
      // Default sections for paid & free trial (22/3/24) - reproduced: 28/3/24
      currentTags = mapTagsToSection([
        "Personal Development",
        "Performance & Productivity",
        "Leadership & Management",
        // "Pengembangan Diri",
        // "Kinerja & Produktivitas",
        // "Kepemimpinan & Manajemen",
      ]);
    }
    /// Section according to KYC (4/4/24)
    if (currentUser?.list_tags && currentUser?.list_tags?.length > 0) {
      currentTags = mapArrayToSection((currentUser as any)?.list_tags);
    }
    setSectionB2B(currentTags);
  }, [currentUser]);

  const loadMoreMandatory = useCallback(() => {
    setPageMandatory((prevState) => prevState + 1);
  }, []);
  const lastMandatoryCourseElm = useInfiniteScroll(
    loadMoreMandatory,
    hasMoreMandatory,
    isLoader
  );
  const updateText = (text) => setSearch(text);
  const debouncedOnChange = debounce(updateText, 800);

  const openModal = () => {
    trackEvent({
      event: "srp_filter_open_a",
      property: {
        page: `B2B Homepage`,
      },
    });
    setShow(false);
  };

  const skeleton = isDesktop ? [1, 2, 3] : [1];
  const skeletonMandatory = isDesktop ? [1, 2, 3, 4] : [1];
  const continueYourCourses = t("continue");
  const q = router?.query?.q ?? null;
  const institutionKeyFilter =
    typeof router.query.institutionKey == "string"
      ? router.query.institutionKey
      : null;

  const tagsKeyFilter =
    typeof router.query.tagsKey == "string" ? router.query.tagsKey : null;
  const sortByFilter =
    typeof router.query.sortBy == "string" ? router.query.sortBy : null;

  const institutionName =
    typeof router.query.institutionName == "string"
      ? router.query.institutionName
      : null;

  const isHidden =
    institutionName ||
    tagsKeyFilter ||
    institutionKeyFilter ||
    q ||
    sortByFilter;

  const [activeTab, setActiveTab] = useState<string | string[]>("mandatory");
  const renderCount = useRef(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const loginState = useAuthStore((state) => state.loginState);

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
    if (renderCount.current > 1) {
      trackEvent({
        event: "b2b_homepage_s",
        property: {
          has_mandatory_course: mandatoryCourses.length > 0 ? true : false,
          language: router?.locale,
        },
      });
    }

    // if (mandatoryCount !== 0) {
    //   setSectionMandatory(SECTION_MANDATORY);
    // }
  }, [mandatoryCount]);
  useEffect(() => {
    if (mandatoryCount === 0) {
      setFetching(true);
      return;
    }
  }, [pageMandatory, mandatoryCount, currentUser]);

  useEffect(() => {
    if (referral) {
      // Fetch custom section
      getSectionCustom.doFetch(
        {
          slug:
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : referral,
        },
        {
          onSuccess(data: any) {
            // console.log(data);
            setSectionPosition(data.data);
          },
        }
      );
      // Fetch others
      getOthers.doFetch(
        {
          slug:
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : referral,
        },
        {
          onSuccess(data: any) {
            setKeywordOthers(
              data?.data.map((el) => {
                return { ...el, active: false };
              })
            );
          },
        }
      );
    }
  }, [referral]);

  useEffect(() => {
    if (loginState === "LOGIN" && currentUser) {
      getCourseMandatory.doFetch(
        {
          slug:
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : currentUser?.referral,
        },
        {
          onSuccess(data: any) {
            // console.log(data);
            let filteredData = data.data.filter((a) => a.courses.length > 0);
            setSectionsMandatory(filteredData);
          },
        }
      );
    }
  }, [loginState]);

  // useEffect(() => {
  //   // Sort section based on sectionPosition
  //   if(sectionB2B.length > 0 && sectionPosition.length > 0){
  //     // Sort position based on sort field
  //     const sortedArray = sectionPosition.sort((a, b) => a.sort - b.sort);

  //     // Flatten the title of section
  //     const sectionTitle = sortedArray.map(item => item.title)

  //     // Format into section
  //     const newSection = mapTagsToSection(sectionTitle)

  //     // Set new section
  //     setSectionB2B(newSection);
  //   }
  // }, [sectionPosition]);

  const handleTagClick = (competenceIndex, tagIndex, info) => {
    const { parent = "", tag = "" } = info;

    trackEvent({
      event: "myinterest_select_a",
      property: {
        selected_type: parent,
        selected_value: tag,
      },
    });

    const updatedCompetences = [...competencesKYC];
    const selectedTag = updatedCompetences[competenceIndex].tags[tagIndex];
    selectedTag.selected = !selectedTag.selected;
    const selectedTagsCount = competencesKYC.flatMap((comp) =>
      comp.tags.filter((tag) => tag.selected)
    ).length;

    if (selectedTagsCount > 3) {
      selectedTag.selected = !selectedTag.selected;
    } else {
      setCompetencesKYC(updatedCompetences);
    }
  };

  useEffect(() => {
    const selectedTags = competencesKYC
      .flatMap((comp) => comp.tags.filter((tag) => tag.selected))
      .map((t) => t.name);

    setKYCTags(selectedTags);
  }, [competencesKYC]);

  const userPreferenceApi = useUserPreference();
  const userGetProfile = useUserGetProfile();
  const authStore = useAuthStore();
  const submitPreference = (isClose = false) => {
    if (!isClose) {
      trackEvent({
        event: "myinterest_submit_a",
        property: {
          submitted_values: selectedKYCTags,
        },
      });

      setSectionB2B([]);
    }
    userPreferenceApi.doFetch(
      {
        tags: isClose ? [] : selectedKYCTags,
      },
      {
        onSuccess(data: any) {
          setShowKYC(false);

          if (!isClose) {
            const sections = mapArrayToSection(data?.data);
            setSectionB2B(sections);
            trackEvent({
              event: "myinterest_submit_success_a",
              property: {
                submitted_values: selectedKYCTags,
              },
            });
          }

          /// Hit CurrentUser API to update the kyc_submitted
          userGetProfile.doFetch(
            {},
            {
              onSuccess(res) {
                authStore.setLogin(res.data);
              },
            }
          );
        },
        onError(e) {
          setShowKYC(false);
        },
      }
    );
  };

  const isMobile = screenWidth < 768;
  const tawkMessengerRef = useRef();

  const onChatMinimized = () => {
    trackEvent({
      event: "chatbot_close_a",
      property: {
        email: currentUser?.email ?? "none",
      },
    });
  };

  const onChatMaximized = () => {
    trackEvent({
      event: "chatbot_open_a",
      property: {
        email: currentUser?.email ?? "none",
      },
    });
  };

  const onChatMessageVisitor = (message) => {
    trackEvent({
      event: "chatbot_user_chat_a",
      property: {
        email: currentUser?.email ?? "none",
        message: message?.message,
      },
    });
  };

  const onFileUpload = (link) => {
    trackEvent({
      event: "chatbot_user_upload_a",
      property: {
        email: currentUser?.email ?? "none",
        link: link,
      },
    });
  };

  const onLoad = () => {
    // const properties = {
    //   name : currentUser?.name ?? 'none',
    //   email : currentUser?.email ?? 'none'
    // }
    // console.log("onload...:",properties)
  };

  /// Dev Tawk To (cs@gokampus.com)
  // const tawkPropertyId = "65f2aa7a9131ed19d979bd5c"
  // const tawkWidgetId = "1hotujt03"
  const [isShowTawkTo, setIsShowTawkTo] = useState(false);
  const [tawkPropertyId, settawkPropertyId] = useState("");
  const [tawkWidgetId, settawkWidgetId] = useState("");

  useEffect(() => {
    if (
      currentUser?.referral === "kemenperin" ||
      currentUser?.referral === "gok-moodle"
    ) {
      /// Kemenperin Tawk To (cs2@gokampus.com)
      settawkPropertyId("660d5b1f1ec1082f04de6876");
      settawkWidgetId("1hqi2ntsc");
      setIsShowTawkTo(true);
    } else {
      settawkPropertyId("");
      settawkWidgetId("");
      setIsShowTawkTo(false);
    }
  }, [currentUser]);

  useEffect(() => {
    // if any filter selected on sidebar, keywords will be inactive
    if (isHidden && keywordOthers.length) {
      setKeywordOthers(keywordOthers.map((el) => ({ ...el, active: false })));
    }
  }, [isHidden]);

  const isAnyFiltered =
    tagsKeyFilter !== null || institutionKeyFilter !== null || q !== null;
  const isKeywordsSearched =
    keywordOthers && keywordOthers.length
      ? keywordOthers.find((a) => a.active == true)?.name
      : null;
  const handleClickClose = () => {
    setKeywordOthers(keywordOthers.map((el) => ({ ...el, active: false })));
    onReset();
    router.push(
      {
        pathname: "/",
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const [isEmptyCourse, setIsEmptyCourse] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { setAllCourse, allCourse } = useCourseStore((c) => ({
    setAllCourse: c.setAllCourse,
    allCourse: c.allCourse,
  }));

  const getAllCourse = async () => {
    try {
      const courseData: any = await getAllCoursesFromIndexedDB();
      if (courseData.length > 0) {
        if (currentUser.email === courseData[0].user.email) {
          setAllCourse(courseData);
          setIsEmptyCourse(false);
        }
        console.log("Data loaded from IndexedDB", courseData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadedCourse = allCourse?.map((course: any) => {
    const { data } = course;

    return {
      ...data,
    };
  });

  useEffect(() => {
    if (currentUser) {
      getAllCourse();
    }
  }, [currentUser]);

  //
  const [previewCourse, setPreviewCourse] = useState(null);
  const setRecommendData = useSearchStore((state) => state.setRecommendData);
  const [tempData, setTempData] = useState(null);
  const accessCourseApi = useAccessCourseTenant();
  const institutionNames =
    typeof router.query.institutionKey == "string" &&
    router.query.institutionKey
      ? router.query.institutionKey.split(",")
      : [];
  const institutionKeyFilter2 = institutionNames.length
    ? `${institutionNames.map((ins) => `institution:"${ins}"`).join(" OR ")}`
    : "";

  const tagsNames =
    typeof router.query.tagsKey == "string" && router.query.tagsKey
      ? router.query.tagsKey.split(",")
      : [];
  const tagsKeyFilter2 = tagsNames.length
    ? `${tagsNames.map((ins) => `tags:"${ins}"`).join(" OR ")}`
    : "";

  const sortByFilter2 =
    typeof router.query.sortBy == "string" ? router.query.sortBy : "";

  const getListCourses = (idxName: string) => {
    const allFilter = [institutionKeyFilter2, tagsKeyFilter2]
      .filter((a) => a)
      .join(" AND ");

    var itHasFilter =
      allFilter ||
      q ||
      (currentUser && currentUser?.referral === "interagroup") ||
      sortByFilter2;

    const add_params =
      allFilter || q || (currentUser && currentUser?.referral === "interagroup")
        ? {
            q,
            facetFilters: allFilter,
          }
        : {
            q,
            facetFilters: allFilter,
          };

    const new_params = new URLSearchParams(
      parseDataParams({ ...add_params })
    ).toString();

    fetch(
      new_params
        ? `/api/search-algolia?indexName=${idxName}&${new_params}`
        : `/api/search-algolia?indexName=${idxName}`
    )
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(true);

        if (res?.data?.hits.length) {
          let algoliaHits = res.data.hits;

          const filterData = algoliaHits.filter(
            (a) => a?.is_subscribe === false
          );
          setPreviewCourse(
            currentUser?.referral == "bluebird" ? algoliaHits : filterData
          );

          /// Run normal function without checking
          /// allowed course or not when user is anonymous
          if (!currentUser?.referral) {
            const data = sortByFilter2
              ? ArrayObjectSortBy(algoliaHits, sortByFilter2, true)
              : q
              ? algoliaHits
              : ShuffleArray(algoliaHits);

            let currentData = null;
            if (page === 2) {
              const uniqueData = data.filter((item) => {
                return !tempData.some(
                  (existingItem) => existingItem.id === item.id
                );
              });
              currentData = itHasFilter
                ? [...data]
                : [...tempData, ...uniqueData];
            } else {
              currentData = data;
              setTempData(data);
            }
            let newArr = currentData;
            if (loginState !== "LOGIN") {
              newArr = currentData.filter((a) => !a.tenant);
            }

            const count = res?.data?.nbHits;
          }

          /// Fetch access course API to get
          /// list of allowed course
          accessCourseApi.doFetch(
            {
              // @ts-ignore
              slug:
                window.location.hostname === "e-learning.bluebirdgroup.com"
                  ? "bluebird"
                  : currentUser?.referral,
            },
            {
              onSuccess(dataApi) {
                const listAccessCourses = dataApi?.data;
                const userId = currentUser?.id;
                const listForbiddenCourses = [];
                const listAllowedCourses = [];
                /// Generate forbidden course where
                /// userId != id from API
                listAccessCourses.forEach((course) => {
                  if (course.user != userId) {
                    const listCoursesSlug = course.courses?.map(
                      (course) => course.slug
                    );
                    listForbiddenCourses.push(...listCoursesSlug);
                  } else {
                    const listCoursesSlug = course.courses?.map(
                      (course) => course.slug
                    );
                    listAllowedCourses.push(...listCoursesSlug);
                  }
                });

                const listForbiddenCoursesAfterFilter =
                  listForbiddenCourses.filter(
                    (course) => !listAllowedCourses.includes(course)
                  );

                /// Hide course where it listed on forbidden courses
                if (listForbiddenCoursesAfterFilter.length > 0) {
                  const showedCourses = algoliaHits.filter((course) => {
                    // console.log("course hits:",course)
                    return !listForbiddenCoursesAfterFilter.includes(
                      course.slug
                    );
                  });
                  // console.log("showedCourses:", showedCourses);
                  algoliaHits = showedCourses;
                }

                const data = sortByFilter2
                  ? ArrayObjectSortBy(algoliaHits, sortByFilter2, true)
                  : q
                  ? algoliaHits
                  : ShuffleArray(algoliaHits);

                let currentData = null;
                if (page === 2) {
                  const uniqueData = data.filter((item) => {
                    return !tempData.some(
                      (existingItem) => existingItem.id === item.id
                    );
                  });
                  currentData = itHasFilter
                    ? [...data]
                    : [...tempData, ...uniqueData];
                } else {
                  currentData = data;
                  setTempData(data);
                }
                let newArr = currentData;
                if (loginState !== "LOGIN") {
                  newArr = currentData.filter((a) => !a.tenant);
                }
              },
              onError(err) {
                const data = sortByFilter2
                  ? ArrayObjectSortBy(algoliaHits, sortByFilter2, true)
                  : q
                  ? algoliaHits
                  : ShuffleArray(algoliaHits);

                let currentData = null;
                if (page === 2) {
                  const uniqueData = data.filter((item) => {
                    return !tempData.some(
                      (existingItem) => existingItem.id === item.id
                    );
                  });
                  currentData = itHasFilter
                    ? [...data]
                    : [...tempData, ...uniqueData];
                } else {
                  currentData = data;
                  setTempData(data);
                }
                let newArr = currentData;
                if (loginState !== "LOGIN") {
                  newArr = currentData.filter((a) => !a.tenant);
                }
              },
            }
          );
        } else {
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let nameOfIndex;
    /// Delete loginState check & dependency to reduce algolia call
    if (!isKeywordsSearched) {
      if (currentUser) {
        nameOfIndex =
          currentUser?.referral &&
          (currentUser?.tenant?.status === "Active" ||
            ["zicare", "bluebird"].includes(currentUser?.referral))
            ? generateIndexName(
                window.location.hostname === "e-learning.bluebirdgroup.com"
                  ? "bluebird"
                  : currentUser?.referral
              )
            : !CONST.IS_DEV
            ? "prod_course_replica_index"
            : "dev_course_replica_index";
      } else {
        nameOfIndex = generateIndexName(
          window.location.hostname === "e-learning.bluebirdgroup.com"
            ? "bluebird"
            : subDomain
        );
        if (!["moodle", "example"].includes(nameOfIndex))
          getCourseRecommend(nameOfIndex);
      }

      if (!["moodle", "example"].includes(nameOfIndex)) {
        getListCourses(nameOfIndex);
      }
    } else {
      const nameOfIndex2 = generateIndexName(
        window.location.hostname === "e-learning.bluebirdgroup.com"
          ? "bluebird"
          : subDomain
      );
      if (!["moodle", "example"].includes(nameOfIndex2)) {
        getListCourses(nameOfIndex2);
      }
    }
  }, [
    currentUser,
    q,
    institutionKeyFilter2,
    tagsKeyFilter2,
    sortByFilter2,
    isKeywordsSearched,
  ]);

  const getCourseRecommend = (idxName) => {
    fetch(idxName ? `/api/search-algolia?indexName=${idxName}` : "/api/search")
      .then((res) => res.json())
      .then((res) => {
        const data =
          ArrayObjectSortBy(res.data?.hits, "created_date", true) ?? [];
        const recommendCourses = data.slice(0, 4);

        if (recommendCourses.length) {
          setRecommendData(recommendCourses);
        }
      });
  };

  useEffect(() => {
    if (indexName && !["moodle", "example"].includes(indexName)) {
      getListCourses(indexName);
    }
  }, [page]);

  const isBlueBirdTenant =
    typeof window !== "undefined" && isBlueBirdURL(window.location.hostname);

  const userLearningHour = useJPTrackers();
  const { setLearningHour } = useLearningHourStore();

  useEffect(() => {
    userLearningHour.doFetch(
      {},
      {
        onSuccess(data: any) {
          setLearningHour(data?.data?.learning_hours);
        },
        onError(err) {
          const error: any = err.getActualType();
          console.log(error);
        },
      }
    );
  }, []);

  return (
    <main>
      {/* {isDesktop && <DownloadAppBanner />} */}
      <PageHeaderDesktop onChangeSearch={debouncedOnChange} />
      {currentUser && isShowTawkTo ? (
        <TawkMessengerReact
          propertyId={tawkPropertyId}
          widgetId={tawkWidgetId}
          onChatMinimized={onChatMinimized}
          onChatMaximized={onChatMaximized}
          onChatMessageVisitor={onChatMessageVisitor}
          onFileUpload={onFileUpload}
          onLoad={onLoad}
          ref={tawkMessengerRef}
        />
      ) : null}

      <Modal
        isBottom={isMobile}
        top={!isMobile ? "5vh" : "0"}
        closeModal={() => submitPreference(true)}
        zIdx={999}
        show={showKYC}
      >
        <div
          className={`bg-white md:w-[50vw] w-[100vw] rounded-16 ${
            isMobile ? "rounded-b-0" : ""
          }`}
        >
          <div className="flex justify-between p-24">
            <p className="text-black text-b1 font-medium">Pilih Preferensi</p>
            <div
              className="cursor-pointer"
              onClick={() => {
                // @ts-ignore
                trackEvent({
                  event: "myinterest_close_a",
                });
                submitPreference(true);
                setShowKYC(false);
              }}
            >
              <IconClose color="#5D586C" size={20} />
            </div>
          </div>
          <KYC
            competencesKYC={competencesKYC}
            handleTagClick={handleTagClick}
            selectedKYCTags={selectedKYCTags}
            submitPreference={submitPreference}
            isLoading={userPreferenceApi.isLoading}
          />
        </div>
      </Modal>

      <InteractiveAvatar />

      <div className="min-h-[80vh]">
        {/* Lanjutkan Kursus Desktop */}
        <div className="continue-course">
          {(myClassAPI.isLoading || onGoingCourses.length > 0) &&
            !sortByFilter &&
            !isAnyFiltered &&
            loginState == "LOGIN" &&
            !isKeywordsSearched && (
              <div className="px-16 md:px-20 py-16 overflow-hidden">
                {!myClassAPI.isLoading ? (
                  <div>
                    <div className="flex justify-between items-center mb-8 lg:mb-16">
                      <Link
                        href={{
                          pathname: "/my-class",
                        }}
                        onClick={() => {
                          trackEvent({
                            event: "b2b_homepage_course_more_a",
                            property: {
                              button_type: "Section Title",
                              section: 0,
                              category_name: `Continue Your Course`,
                            },
                          });
                        }}
                      >
                        <h3 className="text-h4 text-purple80_to_dark10 font-medium">
                          {continueYourCourses +
                            `${
                              onGoingCourses.length > 1 &&
                              continueYourCourses.includes("Continue")
                                ? "s"
                                : ""
                            }`}
                        </h3>
                      </Link>
                      <Link
                        href={{
                          pathname: "/my-class",
                        }}
                        onClick={() => {
                          trackEvent({
                            event: "b2b_homepage_course_more_a",
                            property: {
                              button_type: "",
                              section: 0,
                              category_name: `Continue Your Course`,
                            },
                          });
                        }}
                      >
                        <h3 className="text-b2 lg:text-b1 text-purple50_to_white font-medium cursor-pointer">
                          {t("viewAll")}
                        </h3>
                      </Link>
                    </div>
                    <div
                      className={`flex gap-x-16 overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] ${
                        onGoingCourses.length < 3
                          ? "justify-start"
                          : "justify-between"
                      }`}
                    >
                      <CardContinue onGoingCourses={onGoingCourses} />
                    </div>
                  </div>
                ) : (
                  <SkeletonWrapper className="grid grid-cols-3 gap-x-20">
                    {skeleton.map((el) => (
                      <SkeletonRect
                        key={el}
                        className="h-120 rounded-8 w-full"
                      />
                    ))}
                  </SkeletonWrapper>
                )}
              </div>
            )}
        </div>
        {/* {!isSearchPage && (
          <div
            onClick={openModal}
            className="md:hidden sticky top-[72px] bg-white p-16 z-10"
          >
            <div className="px-16 py-8 bg-purple-05 rounded-8 ">
              <div className="flex items-center justify-center">
                <IconFilter color="#551FC1" />
                <p className="text-purple-50 font-medium text-b2 ml-8">
                  Filter
                </p>
              </div>
            </div>
          </div>
        )} */}
        <ContainerDesktop
          className={
            tagsKeyFilter !== "" || institutionKeyFilter !== ""
              ? "pb-0"
              : "pb-24"
          }
        >
          {/* <FreeTrialBanner className="mt-24 md-max:mb-8 md-max:mt-0" /> */}

          <div className={`flex md:grid md:grid-cols-6 gap-40`}>
            {/* <div className="h-fit shadow-filter sticky z-10 top-[108px] bg-white max-w-[300px] hidden rounded-12 md:block"> */}
            <div className="h-fit sticky z-10 top-[72px] bg-white md:bg-transparent max-w-[300px] hidden rounded-12 md:block">
              <FilterTenant
                dataCompetences={competencesFilter}
                onReset={onReset}
                filters={filters}
                sortDesc={sortDesc}
                currentSort={{ ...currentSort }}
                // update reset keywords when any filter selected
                setSortByDesc={(a) => {
                  setSortByDesc(a);
                  setKeywordOthers(
                    keywordOthers.map((el) => ({ ...el, active: false }))
                  );
                }}
                setFilters={(a) => {
                  setFilters(a);
                  setKeywordOthers(
                    keywordOthers.map((el) => ({ ...el, active: false }))
                  );
                }}
                onSetCurrentSort={(a) => {
                  setCurrentSort({ ...currentSort, a });
                  setKeywordOthers(
                    keywordOthers.map((el) => ({ ...el, active: false }))
                  );
                }}
              />
            </div>

            <div className="col-span-5 home-tenant">
              <div className="flex pt-8">
                {!isSearchPage &&
                  (totalFilter > 0 ? (
                    <div
                      onClick={openModal}
                      // sticky top-[72px] z-10
                      className="md:hidden bg-white py-16 w-full cursor-pointer"
                    >
                      <div className="px-16 py-8 bg-purple-05 rounded-20 border border-purple-50 ">
                        <div className="flex items-center justify-center">
                          <IconFilter color="#551FC1" />
                          <p className="text-purple-50 font-medium text-b2 ml-8">
                            Filter
                          </p>
                          <p className="ml-8 px-4 bg-purple-50 text-white rounded-full text-c1">
                            {totalFilter}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`md:hidden border rounded-full cursor-pointer px-12 py-8 text-center flex items-center m-8 ml-0 text-c1 font-medium border-neutral-40 text-neutral-70`}
                      onClick={openModal}
                    >
                      <IconFilter />
                    </div>
                  ))}
                {isMobileView &&
                  (keywordOthers.length > 0 && !isHidden ? (
                    <div className="flex overflow-x-auto hide-scrollbar">
                      <Keywords
                        keywordOthers={keywordOthers}
                        onReset={onReset}
                        setKeywordOthers={setKeywordOthers}
                        setCoursesAfterClicked={setCoursesAfterClicked}
                      />
                    </div>
                  ) : null)}
              </div>
              {!isMobileView &&
                (keywordOthers.length > 0 && !isHidden ? (
                  <Keywords
                    keywordOthers={keywordOthers}
                    onReset={onReset}
                    setKeywordOthers={setKeywordOthers}
                    setCoursesAfterClicked={setCoursesAfterClicked}
                  />
                ) : null)}

              {referral && referral !== "interagroup" && (
                <div
                  className={`${
                    isHidden || isKeywordsSearched ? "hidden" : ""
                  }`}
                >
                  {/* Kursus Terdownload */}
                  {allCourse?.length > 0 && (
                    <Section
                      data={downloadedCourse}
                      tag={null}
                      title={"Kursus Terdownload"}
                      // title={"sectionPopular"}
                      section={1}
                      emoji={""}
                      id={null}
                      isHidden={isHidden || isKeywordsSearched}
                    />
                  )}

                  {isBlueBirdTenant ? (
                    <>
                      {sectionsMandatory.length > 0
                        ? sectionsMandatory.map((s, idx) => {
                            // console.log(s);
                            return (
                              <SectionMandatory
                                {...s}
                                key={s.id}
                                section={idx}
                                isHidden={isHidden || isKeywordsSearched}
                              />
                            );
                          })
                        : null}

                      {/* Kursus Paling Banyak Dicari */}
                      <Section
                        isPopular={true}
                        data={popularCourse}
                        tag={null}
                        title={"Kursus Paling Banyak Dicari"}
                        // title={"sectionPopular"}
                        section={2}
                        emoji={""}
                        id={null}
                        isHidden={isHidden || isKeywordsSearched}
                      />
                    </>
                  ) : (
                    <>
                      {/* Kursus Paling Banyak Dicari */}
                      <Section
                        isPopular={true}
                        data={popularCourse}
                        tag={null}
                        title={"Kursus Paling Banyak Dicari"}
                        // title={"sectionPopular"}
                        section={2}
                        emoji={""}
                        id={null}
                        isHidden={isHidden || isKeywordsSearched}
                      />

                      {sectionsMandatory.length > 0
                        ? sectionsMandatory.map((s, idx) => {
                            // console.log(s);
                            return (
                              <SectionMandatory
                                {...s}
                                key={s.id}
                                section={idx}
                                isHidden={isHidden || isKeywordsSearched}
                              />
                            );
                          })
                        : null}
                    </>
                  )}

                  {sectionPosition.length > 0
                    ? // Load course directly from section API
                      // If want to load from API, activate the
                      // useEffect "Sort section based on sectionPosition"
                      // and use SectionB2B only
                      sectionPosition.map((s, idx) => {
                        // console.log(s);
                        return (
                          <Section
                            // tag={s.tag.name}
                            id={s.id}
                            key={s.title}
                            tag={s.tag.name}
                            title={s.title}
                            section={idx}
                            emoji={s.emoji}
                            sort={null}
                            data={s.tag.courses}
                            isNeedFetchCourse={false}
                            isHidden={isHidden || isKeywordsSearched}
                          />
                        );
                      })
                    : null}
                  {sectionPosition.length == 0 && sectionB2B.length > 0
                    ? sectionB2B.map((s, idx) => (
                        <Section
                          id="default"
                          key={s.id}
                          tag={s.tag}
                          title={s.title}
                          section={idx}
                          emoji={s.emoji}
                          isHidden={isHidden || isKeywordsSearched}
                        />
                      ))
                    : null}

                  {/* Kursus Terbaru */}
                  <Section
                    key={"Kursus Terbaru"}
                    tag={"newest"}
                    title={"sectionNew"}
                    section={98}
                    sort={"created_date"}
                    emoji={""}
                    id={null}
                    isHidden={isHidden || isKeywordsSearched}
                  />

                  {/* Kursus yang Belum Dipilih Perusahaan */}
                  {/* {(previewCourse?.length > 0 || previewCourse !== null) && (
                    <Section
                      data={previewCourse}
                      tag={null}
                      title={"Kursus yang Belum Dipilih Perusahaan"}
                      section={99}
                      emoji={""}
                      id={null}
                      isHidden={isHidden || isKeywordsSearched}
                    />
                  )} */}
                </div>
              )}

              <div className="hidden md:flex md:flex-col">
                {coursesAfterKeywordClicked.length > 0 &&
                !q &&
                isKeywordsSearched ? (
                  <HomeCardKeywords
                    data={coursesAfterKeywordClicked}
                    isKeywordsSearched={isKeywordsSearched}
                    isSquare={false}
                    handleReset={handleClickClose}
                  />
                ) : (
                  <HomeCard
                    data={coursesAfterKeywordClicked}
                    sections={sectionB2B.length}
                    filters={filters}
                    searchText={searchText}
                    sortDesc={sortDesc}
                    textValue={textValue}
                    isSquare={false}
                    isHidden={isHidden || !isKeywordsSearched}
                    isKeywordsSearched={isKeywordsSearched}
                    handleReset={handleClickClose}
                  />
                )}
              </div>

              <Modal
                show={true}
                isFull
                isBottom
                zIdx={999}
                closeModal={() => setShow(!show)}
                hide={show}
              >
                <div className="shadow-filter bg-white pr-2 rounded-t-12 md:rounded-12">
                  <FilterTenant
                    dataCompetences={competencesFilter}
                    onReset={onReset}
                    setFilters={setFilters}
                    filters={filters}
                    sortDesc={sortDesc}
                    setSortByDesc={setSortByDesc}
                    currentSort={{ ...currentSort }}
                    onSetCurrentSort={(a) =>
                      setCurrentSort({ ...currentSort, a })
                    }
                    closeModal={() => setShow(!show)}
                  />
                </div>
              </Modal>
            </div>
          </div>
        </ContainerDesktop>

        <div className="block md:hidden">
          {isKeywordsSearched && coursesAfterKeywordClicked.length && !q ? (
            <HomeCardKeywords
              data={coursesAfterKeywordClicked}
              isKeywordsSearched={isKeywordsSearched}
              isSquare={true}
              handleReset={handleClickClose}
            />
          ) : (
            <HomeCard
              sections={sectionB2B.length}
              filters={filters}
              searchText={searchText}
              sortDesc={sortDesc}
              textValue={textValue}
              isSquare={true}
              isHidden={isHidden || !isKeywordsSearched}
              isKeywordsSearched={isKeywordsSearched}
              handleReset={handleClickClose}
            />
          )}
        </div>
      </div>

      <BottomNav isDesktop={isDesktop} />
      <ModalSectionsDetail />
    </main>
  );
};
